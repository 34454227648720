import Vue from 'vue'
import router from './router/index'
import App from './App.vue'
import './assets/css/main.css'
import 'tw-elements';

Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
